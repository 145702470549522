@import '../../styles/variables.scss';

.demo-bar {
	&__layout {
		@include demobar-gradient;
		align-items: center;
		color: use-color('white');
		display: flex;
		font-size: 14px;
		font-weight: 400;
		height: $demobar-height;
		justify-content: space-between;
		padding: 3px 15px;
		width: 100%;
	}

	&__info {
		align-items: center;
		column-gap: 6px;
		display: flex;
		font-weight: 500;
	}

	&__memory-layout {
		margin-left: 20px;
	}

	&__progress-bar {
		align-items: center;
		background-image: linear-gradient(
			to right,
			#{use-color('green')} var(--memory-taken),
			rgba(255, 255, 255, 0.38) var(--memory-taken)
		);
		border: solid 1px use-color('light');
		border-radius: 5px;
		display: flex;
		font-size: 13px;
		height: 20px;
		padding-left: 0.5em;
		position: relative;
		--memory-taken: 15%;
		width: 200px;
	}

	&__serifs {
		align-items: flex-end;
		bottom: 0;
		display: flex;
		--serifs-height: 5px;
		justify-content: space-between;
		left: 1%;
		position: absolute;
		right: 1%;
	}

	&__serif {
		background-color: use-color('white');
		height: var(--serifs-height);
		width: 1px;

		&:nth-child(even) {
			height: calc(var(--serifs-height) / 2);
		}
	}

	&__memory {
		font-size: 14px;
		line-height: 20px;
		padding-left: 2px;
		vertical-align: bottom;
	}

	&__button {
		align-items: center;
		background: none;
		border: solid 2px use-color('light');
		display: flex;
		font-size: 14px;
		font-weight: 500;
		height: 27px;
		letter-spacing: 0.1em;
		padding: 0 1em;
		text-transform: uppercase;
	}
}
