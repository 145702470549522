@import 'src/styles/variables';

.slider-input {
	--height: 33px;
	align-items: center;
	column-gap: 10px;
	display: flex;
	--track-height: 6px;
	height: var(--height);
	position: relative;
	--circle-size: 17px;

	&__min,
	&__max {
		font-size: 12px;
		font-weight: 500;

		&.active {
			color: use-color('dark');
			opacity: 1;
		}
	}

	&__track,
	&__active {
		height: var(--track-height);
	}

	&__track {
		background-color: use-color('light-dim');
		border-radius: 5px;
		flex: 1;
		position: relative;
	}

	&__active {
		background-image: linear-gradient(
			180deg,
			use-color('green') 20%,
			use-color('dark-green') 38%
		);
		border-radius: 5px 0 0 5px;
		left: var(--vmin);
		position: absolute;
		width: calc(var(--vmax) - var(--vmin));
	}

	&__circle {
		background-image: linear-gradient(
			135deg,
			use-color('light-dim'),
			use-color('night-green') 50%,
			use-color('dark-green')
		);
		border-radius: 50%;
		box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2);
		cursor: grab;
		height: var(--circle-size);
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		user-select: none;
		width: var(--circle-size);

		&:active {
			cursor: grabbing;
		}

		&[data-name='vmin'] {
			left: var(--vmin);
		}

		&[data-name='vmax'] {
			left: var(--vmax);
		}
	}

	&__value {
		background-color: use-color('dark');
		border-radius: 5px;
		box-shadow: 0 2px 3px rgba(0, 0, 0, 0.21);
		color: white;
		font-size: 10px;
		font-weight: 600;
		height: 1.5em;
		left: 50%;
		line-height: 1.5em;
		position: absolute;
		top: -1.75em;
		transform: translateX(-50%);

		&:not(:empty) {
			padding: 0 0.5em;
		}
	}
}
