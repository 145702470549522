@import '../../styles/variables';

.dnd {
	position: relative;
	user-select: none;

	&__droppable,
	&__draggable {
		height: 100%;
	}

	&__draggable {
		&[draggable='true'] {
			cursor: grab;

			&:active {
				cursor: grabbing;
				opacity: 0.5;
			}
		}
	}

	&__droppable {
		border: solid 1px transparent;

		&.over {
			border-color: use-color('green');
		}

		&.blocked {
			border-color: use-color('red');
		}
	}
}
