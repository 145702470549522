@import 'styles/variables';

.page-plate {
	--gap: 10px;
	background-color: white;
	border-radius: 12px;
	display: inline-block;
	--row-height: 35px;
	padding-bottom: 8px;
	--icon-size: 1.25em;

	&__header,
	&__body {
		padding-left: 14px;
	}

	&__header {
		align-items: center;
		border-bottom: dotted 2px black;
		display: flex;
		height: 50px;
		justify-content: space-between;
		padding-right: 16px;
	}

	&__body {
		padding-right: 32px;
	}

	&__name {
		align-items: center;
		display: flex;
		font-weight: 400;
	}

	&__name > &__icon {
		margin-right: 12px;
	}

	&__button {
		border: 0;
		height: unset;
		line-height: var(--row-height);
		min-width: 43px;
		vertical-align: middle;

		&-icon {
			--size: 1.1em;
			cursor: pointer;
			margin: 0 0.2em -0.2em;
		}
	}

	&__form {
		padding: unset;
	}

	&__fieldset {
		display: grid;
		margin-top: 10px;
		row-gap: var(--gap);

		&-legend {
			font-size: 14px;
			padding: 5px 0 15px;
		}

		&.buttons {
			justify-content: flex-end;
		}
	}

	&__field {
		align-items: center;
		column-gap: 14px;
		display: flex;
		justify-content: space-between;

		&-desc {
			font-size: 14px;
		}
	}

	&__input {
		border: solid 1px #676767;
		border-radius: 6px;
		font-size: 15px;
		padding: 10px 12px;
		text-align: right;
	}

	&__row {
		align-items: center;
		column-gap: var(--gap);
		display: flex;
		font-size: 13px;
		height: var(--row-height);
		line-height: var(--row-height);

		&-item {
			background-color: #f1f1f1;
			border-radius: 4px;
			min-width: 86px;
			text-align: center;

			> span {
				vertical-align: middle;
			}

			&:nth-of-type(2) {
				margin-left: calc(var(--gap) * 2);
			}
		}
	}

	&__list {
		background-color: rgba(0, 0, 0, 0.05);
		display: flex;
		flex-direction: column;
		height: 20vh;
		overflow: auto;
		padding: 5px 0;
		row-gap: 2.5px;

		&-item {
			border-left: solid 5px transparent;
			padding: 5px;

			&.checked {
				border-left-color: use-color('green');
			}
		}

		&-checkbox {
			display: none;
		}
	}

	&__row-item,
	&__button {
		padding: 0 5px;
	}

	&__header &__button {
		padding: 5px;
	}

	.react-date-picker__calendar {
		background: white;
		border-radius: 5px;
		box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
		inset: 100% auto auto 0;
		position: absolute !important;
	}
}
