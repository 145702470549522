@import '../../styles/variables.scss';

.form {
	display: flex;
	flex-direction: column;
	padding: 5em 4em 3em;

	@include mobile {
		padding: 5em 3em 3em;
	}

	&-no-padding {
		padding: 0;
	}

	&__checkbox {
		label > span:not(:empty) {
			margin-left: 0.75em;
		}

		&-mask {
			border-radius: 4px;
			position: relative;
			transition-duration: 150ms;
			transition-property: background-color;
			transition-timing-function: ease-out;

			&--big {
				height: 20px;
				width: 21px;
			}

			&--small {
				background-size: 10px;
				height: 15px;
				width: 15px;
			}

			&--green {
				background-color: use-color('white');
				border: solid 2px use-color('green');
			}

			&--dark {
				background-color: use-color('white');
				border: solid 2px use-color('dark-light');
			}

			&--gray {
				background-color: use-color('white');
				border: solid 2px use-color('gray-light');
			}
		}
	}

	&__radio {
		&-mask {
			background-color: #fefefe;
			border: 2px solid use-color('gray');
			border-radius: 50%;
			height: 21px;
			position: relative;
			width: 21px;

			&:focus,
			&:active {
				&::before {
					border: 4px solid use-color('light-dim');
					content: '';
					display: block;
					height: 23px;
					position: absolute;
					right: -7px;
					top: -7px;
					width: 23px;
				}
			}

			&::after,
			&::before {
				border-radius: 50%;
			}
		}
	}

	&__field {
		position: relative;

		> input,
		> label,
		> .form__checkbox-mask::before,
		> .form__checkbox-mask::after,
		> .form__radio-mask::before,
		> .form__radio-mask::after {
			transition-duration: 150ms;
			transition-timing-function: ease-out;
		}

		&:not(:last-child) {
			position: relative;
		}

		> label {
			align-items: center;
			color: use-color('dark');
			display: flex;
			flex-direction: row;
			position: relative;
		}

		&.form__native > label {
			left: 2%;
			position: absolute;
			top: 15%;

			&:hover .form__checkbox-mask {
				background-color: use-color('dark-light');
			}
		}
	}

	&__input {
		background-color: transparent;
		border: 0;
		border-bottom: solid 1px use-color('dark');
		font-size: 1em;
		height: 2em;
		width: 100%;

		&__file {
			// Do any CSS style here
		}

		&:focus + label,
		&:not(:placeholder-shown) + label,
		&:-webkit-autofill + label {
			color: use-color('green');
			font-size: 0.75em;
			left: 0;
			top: -24%;
		}

		&:focus {
			border-color: use-color('green');
		}

		&__select,
		&__file {
			appearance: none;
			padding: 0;
		}

		&__select {
			background-image: url('../../assets/icons/circle-arrow-down.svg');
			background-position-x: 270px;
			background-position-y: 6px;
			background-repeat: no-repeat;
			background-size: 22px;
			cursor: pointer;
		}

		&__file {
			display: none;

			& + label > &-icon {
				background: url('../../assets/icons/added.svg') left center no-repeat;
				font-size: 14px;
				height: 1.5em;
				line-height: 1.5em;
				padding-left: 2em;
			}

			& + label:hover > &-icon,
			& + label:active > &-icon {
				background-image: url('../../assets/icons/added-hover.svg');
			}
		}
	}

	&__field > input[type='radio'] {
		display: none;

		&:disabled + label {
			cursor: default;

			> .form__radio-mask {
				background-color: #fefefe;
				border-color: use-color('gray-light');
				display: flex;

				&::after {
					background-color: use-color('gray-light');
					content: '';
					height: 11px;
					margin: auto;
					width: 11px;
				}
			}
		}

		&:checked + label {
			> .form__radio-mask {
				background-color: #fefefe;
				border-color: use-color('green');
				display: flex;

				&::after {
					background-color: use-color('green');
					content: '';
					height: 11px;
					margin: auto;
					width: 11px;
				}

				&:focus,
				&:active {
					&::before {
						border-color: #c2e3c2;
					}
				}

				&--gray {
					border-color: use-color('dark');

					&::after {
						background-color: use-color('dark');
						content: '';
						height: 11px;
						margin: auto;
						width: 11px;
					}

					&:focus,
					&:active {
						&::before {
							border-color: use-color('light-dim');
						}
					}
				}
			}
		}
	}

	&__field > input[type='checkbox'] {
		display: none;

		&:disabled + label {
			cursor: default;

			> .form__checkbox-mask {
				background-color: use-color(light-dim);
				border-color: use-color(gray-light);

				&::before,
				&::after {
					background-color: use-color(light-dim);
				}
			}
		}

		&:checked + label {
			> .form__checkbox-mask--green {
				background-image: url('../../assets/icons/check-green.svg');
				background-position: center;
				background-repeat: no-repeat;
				border-color: use-color('green');
			}

			&:hover {
				> .form__checkbox-mask--green {
					background-color: use-color('green');
					background-image: url('../../assets/icons/check-hover.svg');
					background-position: center;
					background-repeat: no-repeat;
				}
			}

			> .form__checkbox-mask--dark {
				background-image: url('../../assets/icons/check-dark.svg');
				background-position: center;
				background-repeat: no-repeat;
				border-color: use-color('dark-light');
			}

			&:hover {
				> .form__checkbox-mask--dark {
					background-color: use-color('dark-light');
					background-image: url('../../assets/icons/check-hover.svg');
					background-position: center;
					background-repeat: no-repeat;
				}
			}

			> .form__checkbox-mask--gray {
				background-image: url('../../assets/icons/check-gray.svg');
				background-position: center;
				background-repeat: no-repeat;
				border-color: use-color('gray-light');
			}

			&:hover {
				> .form__checkbox-mask--gray {
					background-color: use-color('gray-light');
					background-image: url('../../assets/icons/check-hover.svg');
					background-position: center;
					background-repeat: no-repeat;
				}
			}

			> .form__checkbox-mask--light {
				background-image: url('../../assets/icons/check-light.svg');
				background-position: center;
				background-repeat: no-repeat;
				border-color: use-color('gray-light');
			}

			&:hover {
				> .form__checkbox-mask--light {
					background-color: use-color('gray-light');
					background-image: url('../../assets/icons/check-hover.svg');
					background-position: center;
					background-repeat: no-repeat;
				}
			}
		}

		&:checked:disabled + label {
			> .form__checkbox-mask {
				background-color: use-color(light-dim);
				border-color: use-color(gray-light);
				opacity: 0.75;

				&::before,
				&::after {
					background-color: use-color(gray-light);
				}
			}
		}
	}
}

.date-input {
	&.inlined {
		display: inline-block;

		.react-date,
		.react-daterange {
			&-picker {
				&__wrapper {
					display: flex;
					margin: unset;
					width: unset;
				}

				&__inputGroup {
					display: inline-flex;
					margin-top: 0;
				}

				&__calendar {
					background-color: white;
					box-shadow: 0 4px 5px rgba(0, 0, 0, 0.3);
					padding: 0.25em 1em;
					position: absolute !important;
					z-index: 1001;

					&--closed {
						display: none;
					}
				}
			}
		}
	}

	&__date-layout {
		column-gap: 8px;
		display: flex;
		padding: 10px;
	}
}
