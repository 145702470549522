@import '../../styles/variables.scss';

.add__edit {
	&__wrapper {
		background-color: use-color('white');
		border-radius: 1em;
		display: flex;
		flex-direction: column;
		margin-bottom: 2em;
		padding: 1.5em;
	}

	&__header {
		align-items: center;
		border-bottom: dashed 1px use-color(dark-light);
		color: use-color(dark);
		margin-bottom: 2em;
		padding: 0.5em 0;

		h2,
		h3 {
			padding: 0.25em 0;
		}
	}

	&__title {
		color: use-color('gray');
		font-size: 18px;
		font-weight: 500;
		line-height: 21px;
	}

	&__icon {
		height: 1.4em;
		padding: 0 0.5em;
	}

	&__field {
		align-items: center;
		justify-content: center;
		margin-bottom: 2em;

		&__input {
			&_wrapper {
				margin-left: auto;
			}
		}
	}

	&__label {
		font-size: 1em;
	}

	&__hr {
		border-bottom: dashed 1px use-color('gray');
		flex-grow: 1;
		height: 0;
		margin: 0 1em;
	}

	&__form {
		padding: 0;

		&__big_field {
			margin: 2em auto;

			.big-input__form {
				&_hint {
					color: use-color('green');
					display: block;
					font-size: 18px;
					line-height: 21px;
					padding: 0.25em;
				}
			}
		}

		&__field {
			.form {
				&__input {
					border: solid 1px use-color('gray');
					border-radius: 0.3em;
					font-size: 16px;
					font-weight: 300;
					height: 40px;
					line-height: 19px;
					text-indent: 0.5em;
					width: 300px;

					&-category {
						font-weight: 300;
					}

					&_select {
						height: 2.3em;
						padding: 0;
						padding-right: 2.5em;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					+ label {
						font-size: 14px;
						left: 0;
						padding: 1em;
						top: 0;

						@include mobile {
							font-size: 0.9em;
						}
					}

					&:focus {
						border-color: use-color('green');
					}

					&:focus + label,
					&:not(:focus):not(:placeholder-shown) + label {
						font-size: 0.85em;
						left: 0;
						top: 0;
						transform: translate3d(0, -80%, 0);

						@include mobile {
							font-size: 0.7em;
						}
					}

					&:not(:focus):not(:placeholder-shown) + label {
						color: use-color('gray');
					}
				}
			}
		}
	}
}

.auth {
	min-height: 100vh;
}

.content {
	&--authentication {
		height: calc(100% - 75%);
		min-width: unset;
		overflow-y: auto;
	}

	&__wrapper {
		flex-grow: 1;
		position: relative;
	}
}
