@import '../../styles/variables.scss';

.folder {
	&__layout {
		cursor: pointer;
		position: relative;
	}

	&__item {
		display: grid;
		font-size: 14px;
		grid-template-columns: auto;
		grid-template-rows: 74px 54px 32px;
		justify-items: center;
		padding: 20px 20px 10px;
		text-align: center;
	}

	&__icon {
		background-image: url('../../assets/icons/library-folder.svg');
		background-position: top center;
		background-repeat: no-repeat;
		height: 74px;
		width: 100px;
	}

	&__name {
		height: 30px;
		margin-top: 13px;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 92px;
	}

	&__textarea {
		background-color: use-color('white');
		border: solid 2px use-color('green');
		border-radius: 3px;
		display: inline-block;
		height: 21px;
		margin-top: 13px;
		-ms-overflow-style: none;
		padding: 5px;
		resize: none;
		scrollbar-width: none;
		width: 100px;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	&__delete {
		margin-top: 10px;
	}

	&__menu {
		background: use-color('light');
		border-radius: 5px;
		height: 74px;
		left: 100px;
		position: absolute;
		top: 100px;
		width: 227px;
		z-index: 1;
	}

	&__action {
		align-items: center;
		cursor: pointer;
		display: flex;
		font-size: 14px;
		height: 37px;
		padding-left: 10px;

		&:hover,
		&:active {
			background-color: use-color('light-dim');
		}
	}

	&__checkbox {
		appearance: none;
		background-color: use-color('white');
		border: 3px solid use-color('green');
		border-radius: 3px;
		cursor: pointer;
		height: 20px;
		width: 20px;

		&:checked {
			background-image: url('../../assets/icons/check-green.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: 14px;

			&:hover {
				background-color: use-color('green');
				background-image: url('../../assets/icons/check-hover.svg');
			}
		}
	}
}
