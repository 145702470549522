@import 'styles/variables';

.calendar {
	background-color: white;
	border-radius: 6px;
	box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
	color: use-color('dark');
	width: 280px;

	&__view {
		&[data-view='years'],
		&[data-view='months'] {
			.calendar__item {
				border-radius: 50%;
				height: 50px;
				width: 50px;
			}
		}

		&-head,
		&-content {
			column-gap: 5px;
			display: grid;
			padding: 0 8px;
			row-gap: 1px;
		}

		&-head {
			.calendar__item {
				font-size: 13px;
				font-weight: 400;

				&:hover {
					background-color: unset;
					cursor: initial;
				}
			}
		}

		&-content {
			justify-content: space-around;
			padding-bottom: 8px;
		}
	}

	&__item {
		align-items: center;
		border-radius: 50%;
		cursor: pointer;
		display: inline-flex;
		font-size: 14px;
		font-weight: 300;
		grid-column: var(--col);
		grid-row: var(--row);
		height: 33px;
		justify-content: center;
		transition: background-color 0.5s;
		width: 33px;

		&:not(.disabled):not(.selected):hover {
			background-color: rgba(0, 0, 0, 0.05);
		}

		&.selected {
			background-color: use-color('dark-green');
			color: white;
		}

		&.disabled {
			cursor: initial;
		}

		&:not(.selected).between {
			background-color: rgba(0, 0, 0, 0.05);
		}
	}

	&__head {
		align-items: center;
		column-gap: 8px;
		display: flex;
		height: 43px;
		justify-content: space-between;
		padding: 0 8px;
	}

	&__title {
		align-items: center;
		border-radius: 8px;
		display: inline-flex;
		flex: 1;
		font-size: 14px;
		font-weight: 500;
		height: 30px;
		padding: 0 4px;
		transition: background-color 0.5s;

		&:not(:empty) {
			cursor: pointer;

			&:hover {
				background-color: rgba(0, 0, 0, 0.064);
			}
		}
	}

	&__slider {
		align-items: center;
		column-gap: 4px;
		display: flex;

		&-prev,
		&-next {
			align-items: center;
			border-radius: 8px;
			cursor: pointer;
			display: inline-flex;
			height: 30px;
			justify-content: center;
			transition: background-color 0.5s;
			width: 30px;

			&:hover {
				background-color: rgba(0, 0, 0, 0.1);
			}

			.icon {
				--size: 15px;
			}
		}
	}
}
