@import '../../styles/variables.scss';

.sidebar {
	background-color: use-color(dark);
	display: flex;
	flex-direction: column;
	padding: 1.7em 0;
	position: relative;
	text-align: center;

	&__header {
		align-items: center;
		display: flex;
		justify-content: space-between;
	}

	&__logo {
		background-image: url('../../assets/brand/logo-color.svg');
		height: 35px;
		margin-right: 75px;
		width: 149px;
	}

	&__version {
		align-items: center;
		background-color: use-color('green');
		border-radius: 4px;
		color: use-color('white');
		display: flex;
		font-size: 12px;
		font-weight: 900;
		height: 20px;
		justify-content: center;
		position: absolute;
		right: 75px;
		text-transform: uppercase;
		top: 62px;
		width: 40px;

		&--demo {
			background-color: use-color('red');
		}
	}

	&__burger {
		background-image: url('../../assets/icons/burger-menu.svg');
		background-position: center;
		background-repeat: no-repeat;
		border-radius: 2px;
		box-sizing: content-box;
		height: 19px;
		margin-left: 13px;
		padding: 8px 6px;
		width: 22px;

		&:hover,
		&:active {
			background-color: use-color('dark-light');
			margin-left: 13px;
		}
	}

	.menu__wrapper {
		flex: 1;
		margin-top: 3em;
		overflow-y: auto;
	}
}

.main:not(.sidebar-open) {
	.sidebar {
		&__burger {
			background-color: use-color('dark-light');
		}

		&__logo {
			background-image: url('../../assets/brand/logo-mini.svg');
			margin-right: 20px;
			width: 38px;
		}

		&__version {
			right: 19px;
		}

		.menu__item {
			&__title {
				display: none;
			}

			&__list-item {
				overflow: hidden;
				padding: 13px;
				text-align: center;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}
}
