html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
input,
button,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	border: 0;
	font: inherit;
	font-size: 100%;
	margin: 0;
	margin-block-end: 0;
	margin-block-start: 0;
	padding: 0;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

body {
	line-height: 1;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

img {
	border: 0;
}

input,
button,
select,
textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

input,
button,
textarea {
	appearance: none;
	background: none;
	border: 0;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
	outline: none;
}

nav {
	display: block;
}

* {
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}

html,
body,
a,
img {
	-webkit-user-drag: none;
}

label,
input[type='button'],
button,
a {
	cursor: pointer;
}

[aria-disabled],
[disabled] {
	cursor: not-allowed;
	opacity: 0.6;
}

input {
	-webkit-appearance: none;
	appearance: none;
	-webkit-border-radius: 0;
	border-radius: 0;
}

ul {
	list-style-type: none;
}

li {
	list-style-position: outside;
	overflow: hidden;
}
