@import 'styles/variables';

.statistics {
	&__info {
		background-color: use-color('light');
		border: dashed 3px white;
		border-radius: 10px 5px;
		font-size: 14px;
		font-weight: 400;
		line-height: 1.5em;
		max-width: 400px;
		padding: 10px;
	}

	&__period-row {
		height: unset;
	}
}
