@import 'assets/icons/icons.module';

.icon {
	--size: 20px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	display: inline-block;
	height: var(--size);
	vertical-align: baseline;
	width: var(--size);
}
