@import '../../styles/variables';

.media {
	&__list {
		display: grid;
		gap: 20px;
		grid-auto-rows: 190px;
		grid-template-columns: repeat(auto-fit, 140px);
		height: 400px;
		margin-top: 10px;
		overflow-y: auto;

		&__item {
			&-action {
				align-items: center;
				border-radius: 2em;
				display: flex;
				justify-content: center;
				padding: 0.25em;
				transition: all 0.3s;
				width: 50%;

				&:hover,
				&:active {
					background-color: use-color('white');
					border-radius: 0.25em;
					width: 100%;
				}
			}
		}
	}
}

.media-list {
	&__item {
		align-items: center;
		background-color: #dbe0f2;
		border: solid 2px #dbe0f2;
		border-radius: 5px;
		font-size: 12px;
		height: 190px;
		position: relative;
		text-align: center;
		transition: all 0.3s;
		width: 140px;

		&:hover {
			border-color: use-color('green');
		}

		&-filename {
			display: inline-block;
			margin-bottom: 7px;
			margin-top: 5px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 120px;
		}

		&-textarea {
			background-color: use-color('white');
			border: solid 2px use-color('green');
			border-radius: 5px;
			display: inline-block;
			margin-bottom: 1px;
			margin-top: 1px;
			-ms-overflow-style: none;
			resize: none;
			scrollbar-width: none;
			text-align: center;
			width: 100px;

			&::-webkit-scrollbar {
				display: none;
			}
		}

		&-preview {
			border-radius: 3px;
			height: 62px; // TODO: Fix later by size
			margin-top: 10px;
			overflow: hidden;
			position: relative;
			width: 100px; // TODO: Fix later by size

			&_image {
				height: 62px;
				object-fit: contain;
			}

			&_ext {
				background-color: use-color('green');
				border-radius: 3px;
				color: use-color('white');
				font-size: 0.75em;
				font-weight: 600;
				padding: 0.35em;
				position: absolute;
				right: 0;
				text-align: center;
				text-transform: uppercase;
				top: 0;
			}

			&_checkbox {
				align-self: flex-start;
				margin-left: 5px;
				margin-top: -16px;
				transform: scale(0.75);
			}
		}

		&-status {
			align-items: center;
			display: flex;
			text-transform: lowercase;

			img {
				height: 0.75em;
				margin-right: 0.25em;
			}
		}

		&-file-info {
			background-color: use-color('white');
			border: solid 1px use-color(dark);
			margin: 5px 0;
			overflow: hidden;
			padding: 3px;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 130px;

			&_duration,
			&_size {
				padding: 0 0.5em;
			}

			&_duration {
				border-right: solid 1px use-color(dark);
			}
		}

		&-menu {
			background: use-color('light');
			border-radius: 5px;
			height: 74px;
			left: 100px;
			position: absolute;
			top: 100px;
			width: 227px;
			z-index: 1;
		}

		&-action {
			align-items: center;
			cursor: pointer;
			display: flex;
			font-size: 14px;
			height: 37px;
			padding-left: 10px;

			&:hover,
			&:active {
				background-color: use-color('light-dim');
			}
		}

		&__button {
			&_add,
			&_remove {
				background-position: center;
				background-repeat: no-repeat;
				height: 20px;
				transition: all 0.3s;
				width: 20px;
			}

			&_remove {
				background-image: url('../../assets/icons/added-hover.svg');
				transform: rotate(-45deg);

				&:disabled {
					background-image: url('../../assets/icons/added.svg');
					cursor: default;
					opacity: 0.5;
				}

				&:not(:disabled):hover,
				&:not(:disabled):active {
					background: url('../../assets/icons/added.svg') no-repeat center;
				}
			}

			&_add {
				background: url('../../assets/icons/added.svg') no-repeat center;

				&:not(:disabled):hover,
				&:not(:disabled):active {
					background-image: url('../../assets/icons/added-hover.svg');
				}
			}
		}
	}
}

.file-status {
	align-items: center;
	column-gap: 3px;
	display: inline-flex;
	justify-self: center;
	text-transform: lowercase;

	.icon {
		--size: 9px;
	}
}
