@import '../../styles/variables.scss';

.header {
	align-items: center;
	background-color: use-color('gray');
	box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.3);
	color: use-color('white');
	column-gap: 10px;
	display: flex;
	padding: 3px 33px;
	position: relative;

	&__left,
	&__right {
		align-items: center;
		column-gap: 10px;
		display: flex;
		flex: 1;
	}

	&__left {
		justify-content: flex-start;
	}

	&__right {
		justify-content: flex-end;
	}

	&__profile {
		&-block {
			position: relative;
		}

		&-button {
			background-image: url('../../assets/icons/profile.svg');
			height: 20px;
			width: 18px;
		}

		&-menu {
			background: use-color('dark');
			border: 1px solid use-color('gray');
			border-radius: 5px;
			position: absolute;
			right: 0;
			top: 28px;
			width: 200px;
			z-index: 1;
		}
	}

	&__logout {
		background-image: url('../../assets/icons/profile-logout.svg');
		background-repeat: no-repeat;
		background-size: contain;
		cursor: pointer;
		height: 18px;
		margin-left: 19px;
		width: 24px;
	}

	&__search {
		&__form {
			flex-basis: 40%;
			flex-direction: row;
			position: relative;
			visibility: hidden;

			> .form__field {
				margin: 0;
				width: 100%;
			}
		}

		&__input {
			background-color: use-color('white');
			border: solid 1px use-color(light-dim);
			border-radius: 2em;
			margin: 0.25em;
			padding: 1.25em;
			padding-right: 3em;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		&__submit {
			appearance: none;
			background-color: transparent;
			border: 0;
			outline: 0;
			padding: 0.85em;
			position: absolute;
			right: 0;
		}

		&__icon {
			height: 1.5em;
		}
	}

	&__balance {
		align-items: center;
		border: 1px solid use-color('white');
		border-radius: 5px;
		cursor: pointer;
		display: flex;
		font-size: 14px;
		height: 30px;
		justify-content: flex-end;
		min-width: 80px;
		padding: 0 8px;
	}

	&__add-money {
		align-items: center;
		border: 2px solid use-color('dark-green');
		column-gap: 5px;
		display: flex;
		font-size: 12px;
		height: 30px;
		justify-content: space-between;
		min-width: unset;
		padding: 0 6px;
	}

	&__monitors {
		&-list {
			align-items: center;
			border: 1px solid use-color('white');
			border-radius: 5px;
			display: flex;
			font-size: 12px;
			height: 30px;
			justify-content: space-between;
			padding: 0 10px;
			width: 78px;
		}

		&-group {
			align-items: center;
			display: flex;
			flex-direction: column;
			height: 20px;
			justify-content: space-between;
		}

		&-light {
			height: 5px;
			width: 5px;

			&--red {
				background-color: use-color('red');
			}

			&--green {
				background-color: use-color('dark-green');
			}

			&--gray {
				background-color: use-color('light-dim');
			}
		}
	}

	&__email,
	&__languages {
		align-items: center;
		border-radius: 1em;
		display: flex;
		height: 3em;
		max-width: 300px;
		padding: 0.85em 0;
	}

	&__email {
		align-items: flex-end;
		align-self: baseline;
		display: flex;
		flex-direction: column;

		&-name {
			font-size: 15px;
			font-weight: 400;
		}

		&-email {
			font-size: 11px;
			font-weight: 500;
			opacity: 0.7;
		}
	}

	&__menu {
		align-items: center;
		column-gap: 10px;
		display: flex;

		&-item {
			align-items: center;
			display: flex;
		}

		&-icon {
			display: inline-block;
			margin: -2px 6px 0 0;
		}
	}
}

.profile-menu {
	&__item {
		align-items: center;
		background-position: 10px 13px;
		background-repeat: no-repeat;
		border-bottom: 1px dashed use-color('gray');
		display: flex;
		font-size: 14px;
		height: 40px;
		justify-content: left;
		padding-left: 40px;
		transition: all 0.3s;

		&:last-child {
			border-bottom: 0;
		}

		&:hover,
		&:focus {
			background-color: use-color('dark-light');
		}
	}
}

.react-select {
	&__container {
		font-size: 12px;
		font-weight: 500;
		text-transform: uppercase;
	}

	&__control {
		background: use-color('dark-green');
		border-radius: 22px;
		cursor: pointer;
		height: 30px;
		width: 60px;
	}

	&__value-container {
		align-items: center;
		background-color: use-color('dark-green');
		background-image: url('../../assets/icons/select-arrow-chosen.svg');
		background-position: 8px 14px;
		background-repeat: no-repeat;
		border-radius: 22px;
		color: use-color('white');
		display: flex;
		height: 100%;
		justify-content: center;
		padding-left: 6px;
		width: 100%;
	}

	&__menu-list {
		padding: 0;
	}

	&__menu {
		border-radius: 5px;
		cursor: pointer;
		overflow: hidden;
		position: absolute;
		z-index: 1;
	}

	&__option {
		align-items: center;
		background-color: use-color('light-dim');
		background-image: url('../../assets/icons/select-arrow.svg');
		background-position: 8px 11px;
		background-repeat: no-repeat;
		border-bottom: 1px dashed use-color('gray');
		color: use-color('gray');
		display: flex;
		height: 30px;
		justify-content: center;
		padding-left: 6px;
		width: 60px;

		&:last-child {
			border-bottom: 0;
		}

		&:hover,
		&:active {
			background-color: use-color('dark-green');
			color: use-color('white');
		}
	}
}
