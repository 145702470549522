@import '../../styles/variables.scss';

.library {
	.add__edit__title {
		font-weight: normal;
	}

	&__head {
		&.flex.row {
			column-gap: 18px;
		}

		.icon {
			--size: 27px;
			cursor: pointer;

			&[data-action='Create directory'] {
				--size: 36px;
			}

			&[data-action='Cut'] {
				--size: 32px;
			}

			&[data-action='Delete'] {
				--size: 26px;
			}
		}
	}

	&__folder-path-layout {
		align-items: center;
		background-color: #dbe0f2;
		border-radius: 5px;
		display: flex;
		margin-top: 10px;
		padding: 6px 10px;
		width: 100%;
	}

	&__path-circle {
		background-image: url('../../assets/icons/library-up-menu.svg');
		background-repeat: no-repeat;
		background-size: 20px;
		height: 20px;
		margin-right: 10px;
		width: 20px;
	}

	&__folder-path {
		font-size: 14px;
		line-height: 16px;
		max-width: 95%;
	}

	&__folder-path-item {
		color: use-color('dark');

		&:active {
			color: #0a0a0a;
		}
	}

	&__list {
		display: grid;
		gap: 20px;
		grid-auto-rows: 190px;
		grid-template-columns: repeat(auto-fit, 140px);
		height: 400px;
		margin-top: 10px;
		overflow-y: auto;

		&__item {
			&-delete {
				align-items: center;
				border-radius: 2em;
				display: flex;
				justify-content: center;
				padding: 0.25em;
				transition: all 0.3s;
				width: 100%;

				&:hover,
				&:active {
					background-color: use-color('white');
					border-radius: 0.25em;
					width: 100%;
				}
			}
		}
	}

	&__item {
		background-color: #dbe0f2;
		border: solid 2px #dbe0f2;
		border-radius: 5px;
		height: 190px;
		width: 140px;

		&:hover {
			border-color: use-color('green');
		}

		&--empty {
			background-color: #dbe0f2;
			border: solid 2px #dbe0f2;
			border-radius: 5px;
		}

		&.folder__item {
			grid-template-rows: 74px 54px;
			height: unset;

			.folder__checkbox {
				display: none;
			}
		}
	}

	&__item-actions {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
	}

	&__item-download {
		color: use-color('dark');
		cursor: pointer;
		display: inline-block;
		margin-bottom: 8px;

		&:hover,
		&:active {
			color: #0a0a0a;
		}
	}

	&__item-checkbox {
		appearance: none;
		background-color: use-color('white');
		border: 3px solid use-color('green');
		border-radius: 3px;
		cursor: pointer;
		height: 20px;
		width: 20px;

		&:checked {
			background-image: url('../../assets/icons/check-green.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: 14px;

			&:hover {
				background-color: use-color('green');
				background-image: url('../../assets/icons/check-hover.svg');
			}
		}
	}

	&__grid {
		padding: 10px 0;
	}
}
