@import '../../styles/variables.scss';

.add__edit {
	&__form {
		&__require {
			&_edit {
				background: url('../../assets/icons/error.svg') 5% center no-repeat;
				background-size: auto 40%;
				border: solid 1px use-color('green');
				border-radius: 0.5em;
				color: use-color('green');
				font-weight: 400;
				margin: 1em 0;
				padding: 1em;
				padding-left: 4em;
			}
		}

		&__actions {
			margin: 22px 0 0 auto;

			.button {
				margin: 0 0 0 10px;
			}
		}
	}

	&__photos {
		&__layout {
			align-items: center;
			flex-basis: 60%;
			justify-items: center;
		}

		&__wrapper {
			align-items: center;
			margin-left: 1em;
			padding: 0 2em;
			position: relative;
		}

		&__navigation {
			background: url('../../assets/icons/circle-arrow.svg') center no-repeat;
			background-size: 22px;
			color: transparent;
			height: 24px;
			position: absolute;
			transition: all 0.3s;
			width: 24px;

			&[disabled] {
				opacity: 0.5;
			}

			&-prev {
				left: 0;
				transform: rotate(180deg);

				&:hover,
				&:active {
					transform: rotate(180deg) scale(1.1);
				}
			}

			&-next {
				right: 0;

				&:hover,
				&:active {
					transform: scale(1.1);
				}
			}
		}

		&__item {
			align-items: center;
			border-radius: 0.5em;
			flex-grow: 1;
			justify-items: center;
			margin: 0 0.5em;
			overflow: hidden;
			position: relative;

			&-photo {
				height: 6em;

				@include tablet {
					height: 4.5em;
				}
				@include mobile {
					height: 4em;
				}
			}

			&-ext,
			&-filename,
			&-remove {
				background-color: use-color('dark');
				color: use-color('green');
				font-size: 0.65em;
				font-weight: 600;
				overflow: hidden;
				padding: 3px;
				position: absolute;
				text-align: center;
				text-overflow: ellipsis;
				transition: all 0.2s;
				white-space: nowrap;
			}

			&-ext {
				right: 0;
				top: 0;
			}

			&-filename {
				left: 0;
				padding-right: 2em;
				top: 0;
				width: 100%;
			}

			&-remove {
				background-color: use-color('green');
				border-radius: 50%;
				bottom: 0;
				padding: 3px;
				width: 2.5em;

				&:hover,
				&:active {
					border-radius: 0;
					width: 100%;
				}

				> img {
					height: 1.5em;

					@include mobile {
						height: 1.25em;
					}
				}
			}
		}
	}

	&__documents {
		&__layout {
			align-items: center;
			flex-basis: 60%;
			justify-items: center;
		}

		&__wrapper {
			align-items: center;
			flex-grow: 1;
			max-height: 8em;
			overflow-y: auto;
			padding: 0 1em;
			position: relative;
		}

		&__item {
			align-items: center;
			justify-content: space-between;
			position: relative;
			width: 100%;

			&:not(:last-child) {
				border-bottom: dashed 1px use-color(gray-light);
			}

			&-ext,
			&-link,
			&-remove {
				border-radius: 5px;
				color: use-color(dark);
				font-size: 0.75em;
				overflow: hidden;
				padding: 0.5em;
				text-align: right;
				text-overflow: ellipsis;
				transition: all 0.3s;
			}

			&-ext {
				background-color: use-color('gray');
				color: use-color('white');
				font-weight: 600;
			}

			&-link {
				color: use-color(dark);
				flex-grow: 0.9;
				font-size: 1em;

				&:hover,
				&:active {
					background-color: use-color(light-dim);
				}
			}

			&-remove {
				border-radius: 50%;
				font-size: 0.75em;

				> img {
					height: 1em;
				}
			}
		}
	}
}
