@import 'styles/variables.scss';

.main {
	--demobar-height: 0px;
	--header-height: #{$header-height};
	--sidebar-width: #{$sidebar-width};

	&__header,
	&__sidebar,
	&__demobar {
		z-index: 100;
	}

	&__title {
		font-size: 21px;
		font-weight: 500;
		margin: $content-padding 0;
	}

	&__desc {
		color: use-color('gray');
		font-size: 14px;
		font-weight: 400;
		line-height: 1.25em;
		margin: calc($content-padding * -0.25) 0 $content-padding;
		max-width: 1280px;
	}

	&__subtitle {
		font-size: 16px;
		font-weight: 500;
		margin: $content-padding 0;
	}

	&__content {
		margin-left: var(--sidebar-width);
		padding-bottom: $content-padding;

		> *:first-child:last-child {
			margin-top: $content-padding;
			padding-left: $content-padding;
			padding-right: $content-padding;
		}

		@include tablet {
			margin-left: $sidebar-width;
		}
		@include mobile {
			margin-left: $sidebar-width;
		}
	}

	&__header {
		height: var(--header-height);
		position: sticky;
		top: var(--demobar-height);
	}

	&__sidebar {
		bottom: 0;
		left: 0;
		position: fixed;
		top: var(--demobar-height);
		transition: width 0.2s ease;
		width: var(--sidebar-width);
	}

	&__demobar {
		height: var(--demobar-height);
		position: sticky;
		top: 0;
	}

	&--with-demobar {
		--demobar-height: #{$demobar-height};
	}

	&.sidebar-open {
		--sidebar-width: #{$sidebar-width--open};
	}
}
