.layout {
	&__flex {
		display: flex;

		&-wrap {
			flex-wrap: wrap;
		}

		&-column {
			flex-flow: column;
		}

		&-row {
			column-gap: 15px;
			flex-flow: row;
		}

		&.align {
			align-items: center;
		}
	}
}
