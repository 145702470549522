@import 'styles/variables';

.not-found {
	align-items: center;
	background-color: white;
	border: dashed 15px use-color('light');
	border-radius: 40px;
	display: inline-flex;
	flex-direction: column;
	left: 50%;
	max-width: 100%;
	padding: 40px 50px;
	position: fixed;
	top: 50%;
	transform: translate(-50%, -50%);

	.main__title {
		color: use-color('red');
		font-size: 25px;
		font-weight: 600;
		margin-top: 30px;
	}
}
