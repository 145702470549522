.menu__item__icon {
	&Desktop {
		background-image: url('../../assets/icons/desktop.svg');

		&--active {
			background-image: url('../../assets/icons/desktop-active.svg');
		}
	}

	&Bids {
		background-image: url('../../assets/icons/bids.svg');

		&--active {
			background-image: url('../../assets/icons/bids-active.svg');
		}
	}

	&Monitors {
		background-image: url('../../assets/icons/monitors.svg');
		height: 20px;
		margin-left: 15px;
		width: 31px;

		&--active {
			background-image: url('../../assets/icons/monitors-active.svg');
		}
	}

	&Playlists {
		background-image: url('../../assets/icons/playlists.svg');

		&--active {
			background-image: url('../../assets/icons/playlists-active.svg');
		}
	}

	&Library {
		background-image: url('../../assets/icons/library.svg');

		&--active {
			background-image: url('../../assets/icons/library-active.svg');
		}
	}

	&NotFound {
		background-image: url('../../assets/icons/settings.svg');
		height: 20px;
		width: 21.5px;

		&--active {
			background-image: url('../../assets/icons/settings-active.svg');
		}
	}

	&Users {
		background-image: url('../../assets/icons/users.svg');
		height: 20px;
		width: 25px;

		&--active {
			background-image: url('../../assets/icons/users-active.svg');
		}
	}

	&Editor {
		background-image: url('../../assets/icons/editor.svg');
		height: 20px;
		width: 22px;

		&--active {
			background-image: url('../../assets/icons/editor-active.svg');
		}
	}

	&Profile {
		background-image: url('../../assets/icons/profile-settings.svg');
	}

	&Report {
		background-image: url('../../assets/icons/profile-statistics.svg');
	}

	&Logout {
		background-image: url('../../assets/icons/profile-logout-green.svg');
	}
}
