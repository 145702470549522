@import 'styles/variables';

.placemark {
	--size: 32px;
	position: relative;
	transform: translate(-50%, -100%);
	transform-origin: center bottom;

	&__icon {
		background: use-color('dark-light');
		border: 2px solid currentColor;
		border-radius: var(--size) var(--size) 0 var(--size);
		color: use-color('light-dim');
		display: inline-flex;
		height: var(--size);
		transform: rotate(45deg);
		width: var(--size);

		&-label {
			align-items: center;
			display: flex;
			flex: 1;
			flex-direction: column;
			font-size: 14px;
			font-weight: bold;
			justify-content: center;
			text-align: center;
			transform: rotate(-45deg);
		}

		&::after {
			background: rgba(128, 128, 128, 0.2);
			border-radius: 100%;
			content: '';
			height: 75%;
			left: 100%;
			pointer-events: none;
			position: absolute;
			top: 100%;
			transform: translate(-50%, -50%) rotate(45deg) scaleX(0.5);
			user-select: none;
			width: 75%;
		}
	}

	&__popup {
		bottom: calc(var(--size) + var(--angle-size-half) + 5px);
		position: absolute;
		--angle-size: 16px;
		--angle-size-half: calc(var(--angle-size) / 2);
		transform: translateX(var(--translate-x));
		--translate-x: calc((50% - (var(--size) / 2)) * -1);

		&-content {
			background-color: white;
			border: solid 1px use-color('light-dim');
			border-radius: 22px;
			max-height: 200px;
			max-width: 300px;
			overflow: auto;
			width: 100vw;
		}

		&::before {
			background-color: use-color('dark-light');
			border-bottom-right-radius: 2px;
			bottom: calc(var(--angle-size-half) * -1);
			content: '';
			height: var(--angle-size);
			left: calc(50% - var(--angle-size-half));
			position: absolute;
			transform: rotate(45deg);
			width: var(--angle-size);
			z-index: -1;
		}
	}
}
