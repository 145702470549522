@import '../../styles/variables.scss';

.table {
	border-radius: 5px;
	font-size: 15px;
	--td-height: 50px;
	--head-sticky-top: 0px;
	--td-width: 160px;

	&__table {
		width: 100%;
	}

	&.scroll {
		overflow: auto;
		position: relative;
	}

	tr {
		background-color: var(--row-color);
		--row-color: #{use-color('white')};

		th,
		td {
			background-color: var(--row-color);
		}
	}

	th {
		--td-height: 40px;
	}

	th,
	td {
		height: var(--td-height);
		padding: 0 1em;
		transition: max-width 300ms;
		vertical-align: middle;

		ul {
			line-height: 1.33em;
			padding: 0.5em 0;
		}
	}

	&__head {
		color: use-color('white');
		text-align: left;

		tr {
			--row-color: #{use-color('gray-light')};
			position: sticky;
			top: var(--head-sticky-top);
			z-index: 1;

			th {
				position: relative;

				&:not(:first-child) {
					border-left: solid 2px currentColor;
				}

				&:not(.no-arrow) {
					cursor: pointer;
					padding-right: 20px;

					.table__head-filters:not(:empty) {
						right: 22px;
					}
				}
			}
		}

		&-title {
			display: inline-block;
		}

		&-filters {
			align-items: center;
			bottom: 0;
			cursor: pointer;
			display: flex;
			position: absolute;
			right: 5px;
			top: 0;

			.icon {
				--size: 18px;
			}
		}

		&-sort {
			--size: 21px;
			position: absolute;
			right: 2px;
			top: calc(50% - (var(--size) / 2));
			width: calc(var(--size) * 0.75);
		}
	}

	&__body {
		tr {
			--row-color: #{use-color('white')};

			td {
				&:first-child {
					text-align: left;
				}

				.form__checkbox {
					transform: scale(0.75);
					transform-origin: center;

					&-mask {
						margin: 0;
					}

					label > span:not(:empty) {
						margin-left: unset;
					}
				}
			}

			&:nth-child(even) {
				--row-color: #{use-color('light')};
			}
		}
	}

	&__head-title,
	td {
		@include eclipses-text(160px);
	}

	&__icon {
		--size: 22.5px;
		display: inline-block;
		height: var(--size);
		margin-right: 0.5em;
		vertical-align: middle;

		&-condition {
			height: 10px;
			margin-right: 12px;
			padding: 0;
			width: 10px;
		}

		&__action {
			border-radius: 0.5em;
			height: 26px;
			margin-right: 10px;
			padding: 2px;
			transition: all 0.3s;

			&:hover,
			&:active {
				background-color: use-color('light-dim');
			}
		}

		&-pointer {
			cursor: pointer;
		}

		&-favorite {
			margin-right: 0;
		}
	}
}
