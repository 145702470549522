.invoice {
	&-actions,
	&-status {
		align-items: center;
		column-gap: 6px;
		display: inline-flex;

		.button {
			font-size: 14px;
			height: 32px;
			line-height: 32px;
			padding: 0 4px;
			white-space: nowrap;
			width: unset;
		}
	}

	&-actions {
		&__file {
			font-size: 13px;
			font-weight: 400;
			opacity: 0.9;
			transition: opacity 0.5s;

			.icon {
				--size: 13px;
				margin-left: 1px;
			}

			&.as-button {
				cursor: pointer;

				&:hover {
					opacity: 1;
				}
			}
		}

		&__download-origin {
			display: inline-flex;

			&.button {
				padding-left: 0;
				padding-right: 0;
			}

			> div:first-child {
				padding-left: 8px;
				padding-right: 8px;
			}

			&.mini {
				min-width: unset;
				padding: 0;
			}
		}
	}

	&-status {
		margin-left: 6px;
	}

	&__table {
		font-size: 15px;

		td,
		th {
			max-width: unset;
		}
	}
}

#accountantInvoices {
	.page-plate__body {
		padding: 10px 0;
	}
}
