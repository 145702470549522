@import '../../styles/variables.scss';

.menu {
	&__item {
		align-items: center;
		background-color: use-color('dark');
		border-bottom: 1px dashed use-color('gray');
		display: grid;
		grid-template-columns: 65px 200px 35px;
		padding: 0;
		transition: all 0.3s;
		width: 100%;

		&:not(&-active) {
			&:hover,
			&:focus {
				background-color: use-color('dark-light');
			}
		}

		&__wrapper {
			overflow: hidden;
			transition: all 0.3s;
		}

		&__arrow {
			background-color: transparent;
			background-image: url('../../assets/icons/gray-arrow.svg');
			background-position: center right;
			background-repeat: no-repeat;
			height: 24px;
			width: 24px;

			&-mock {
				background: none;
			}
		}

		&__list {
			max-height: 0;
			transition: all 0.3s;

			&-item {
				background-color: use-color('gray');
				display: block;
				font-size: 12px;
				line-height: 14px;
				padding: 13px 0 13px 74px;
				text-align: left;
				transition: background-color 0.3s;

				&--active {
					background-color: use-color('dark-light');
					background-image: url('../../assets/icons/sub-menu-active.svg');
					background-position-x: 65px;
					background-position-y: center;
					background-repeat: no-repeat;
				}

				&:hover,
				&:active {
					background-color: use-color('dark-light');
				}

				&:not(:last-child) {
					position: relative;

					&::after {
						border-bottom: 1px dashed use-color('dark');
						bottom: 0;
						content: ' ';
						left: 0;
						position: absolute;
						width: 100%;
					}
				}

				@include tablet {
					font-size: 0.75em;
					padding: 0.5em;
				}

				@include mobile {
					font-size: 0.65em;
					padding: 0.5em 3em;
				}
			}
		}

		&-no-submenu &__list {
			display: none;
		}

		&-appear &__arrow {
			background-image: url('../../assets/icons/gray-arrow-up.svg');
		}

		&-active &__arrow:not(&__arrow-mock) {
			background-image: url('../../assets/icons/white-arrow-up.svg');
		}

		&-active:not(&-appear) &__arrow:not(&__arrow-mock) {
			background-image: url('../../assets/icons/white-arrow.svg');
		}

		&-active &__arrow-mock {
			background: none;
		}

		&-active,
		&-active:hover {
			background-color: use-color('green');
			border-bottom: 0;
		}

		&__icon {
			background-repeat: no-repeat;
			background-size: contain;
			display: inline-block;
			height: 20px;
			margin-left: 19px;
			width: 21.8px;
		}

		&__title {
			font-size: 14px;
			line-height: 16px;
			text-align: start;

			@include tablet {
				font-size: 0.85em;
			}
			@include mobile {
				font-size: 0.75em;
			}
		}
	}
}
