@import 'variables';

:root {
	--scrollbar-border-radius: 14px;

	::-webkit-scrollbar {
		height: 11px;
		width: 11px;
	}

	::-webkit-scrollbar-track {
		background-color: use-color('gray-light');
		border-radius: 0 var(--b-rad) var(--b-rad) 0;
		--b-rad: calc(var(--scrollbar-border-radius) / 2);
	}

	::-webkit-scrollbar-corner {
		background-color: use-color('light-dim');
	}

	::-webkit-scrollbar-button {
		display: none;
		height: 0;
		width: 0;
	}

	::-webkit-scrollbar-thumb {
		background-color: use-color('green');
		border-radius: var(--scrollbar-border-radius);
		width: 0.5em;
	}
}
