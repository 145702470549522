@import '../../styles/variables.scss';

.popup-fullscreen {
	&__shadow {
		background-color: rgba(71, 79, 100, 0.9);
		bottom: 0;
		display: flex;
		left: 0;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 1000;
	}

	&__container {
		margin: auto;
		min-width: 400px;
	}

	&__icon {
		background-image: url('../../assets/icons/video-project-icon.svg');
		height: 55px;
		margin: 0 auto 18px;
		width: 60px;
	}

	&__title {
		color: use-color('white');
		font-size: 18px;
		font-weight: 400;
		line-height: 21px;
		margin-bottom: 10px;
		text-align: center;
	}

	&__input {
		background-color: use-color('white');
		background-image: url('../../assets/images/loader.png');
		background-position-x: 360px;
		background-position-y: center;
		background-repeat: no-repeat;
		background-size: 30px;
		border-radius: 10px;
		height: 40px;
		margin-bottom: 20px;
		padding: 10px;
		width: 400px;
	}

	&__create-button {
		background-color: use-color('dark-green');
		border: 3px solid use-color('dark-green');
		border-radius: 4px;
		color: use-color('white');
		display: block;
		height: 40px;
		margin: 0 auto;
		width: 140px;

		&:hover {
			border: 3px solid use-color('light-dim');
		}

		&:active {
			border: 3px solid rgba(0, 0, 0, 0.2);
		}
	}

	&__close-button {
		background-image: url('../../assets/icons/close-button-hollow.svg');
		height: 36px;
		position: absolute;
		right: 1.8vw;
		top: 1.8vw;
		transition: $main-transition;
		width: 36px;

		&:hover,
		&:active {
			background-image: url('../../assets/icons/close-button-hollow-hover.svg');
		}
	}

	&__error-container {
		background-color: use-color('red');
		border-radius: 10px;
		color: use-color('white');
		height: 80px;
		margin-top: 20px;
		padding: 10px 0;
		width: 400px;
	}

	&__error-title {
		background-image: url('../../assets/icons/project-error-icon.svg');
		background-position-x: left;
		background-position-y: center;
		background-repeat: no-repeat;
		background-size: 20px 18px;
		color: use-color('white');
		font-size: 18px;
		font-weight: 400;
		height: 22px;
		line-height: 21px;
		margin-left: 139px;
		text-align: center;
		width: 130px;
	}

	&__error-value {
		margin-top: 14px;
		text-align: center;
	}
}
