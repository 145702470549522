/* tslint:disable */
/* eslint-disable */

/**
 * AUTO-GENERATED FILE - DO NOT EDIT!
 *
 * This file was automatically generated by scripts/icons-as-module.js
 * $ yarn run icons-as-module
 *
 */
.icon {
	background-image: var(--icon-url);

	&.AddedHover {
		--icon-url: url('added-hover.svg');
	}

	&.AddedMonitor {
		--icon-url: url('added-monitor.svg');
	}

	&.Added {
		--icon-url: url('added.svg');
	}

	&.ArrowMixed {
		--icon-url: url('arrow-mixed.svg');
	}

	&.Atm {
		--icon-url: url('atm.svg');
	}

	&.AudioFile {
		--icon-url: url('audio-file.svg');
	}

	&.BeautySalon {
		--icon-url: url('beauty-salon.svg');
	}

	&.Bell {
		--icon-url: url('bell.svg');
	}

	&.BidsActive {
		--icon-url: url('bids-active.svg');
	}

	&.Bids {
		--icon-url: url('bids.svg');
	}

	&.BottomArrow {
		--icon-url: url('bottom-arrow.svg');
	}

	&.BurgerMenu {
		--icon-url: url('burger-menu.svg');
	}

	&.Cafe {
		--icon-url: url('cafe.svg');
	}

	&.CancelProjectHover {
		--icon-url: url('cancel-project-hover.svg');
	}

	&.CancelProject {
		--icon-url: url('cancel-project.svg');
	}

	&.CarRepair {
		--icon-url: url('car-repair.svg');
	}

	&.CarWashing {
		--icon-url: url('car-washing.svg');
	}

	&.Cart {
		--icon-url: url('cart.svg');
	}

	&.CheckBox {
		--icon-url: url('check-box.svg');
	}

	&.CheckDark {
		--icon-url: url('check-dark.svg');
	}

	&.CheckGray {
		--icon-url: url('check-gray.svg');
	}

	&.CheckGreen {
		--icon-url: url('check-green.svg');
	}

	&.CheckHover {
		--icon-url: url('check-hover.svg');
	}

	&.CheckLight {
		--icon-url: url('check-light.svg');
	}

	&.Cinema {
		--icon-url: url('cinema.svg');
	}

	&.CircleArrowDisabled {
		--icon-url: url('circle-arrow-disabled.svg');
	}

	&.CircleArrowDown {
		--icon-url: url('circle-arrow-down.svg');
	}

	&.CircleArrow {
		--icon-url: url('circle-arrow.svg');
	}

	&.CloseButtonActive {
		--icon-url: url('close-button-active.svg');
	}

	&.CloseButtonHollowHover {
		--icon-url: url('close-button-hollow-hover.svg');
	}

	&.CloseButtonHollow {
		--icon-url: url('close-button-hollow.svg');
	}

	&.CloseButton {
		--icon-url: url('close-button.svg');
	}

	&.ConfirmEmail {
		--icon-url: url('confirm-email.svg');
	}

	&.CopyMedia {
		--icon-url: url('copy-media.svg');
	}

	&.CreateFolder {
		--icon-url: url('create-folder.svg');
	}

	&.CutMedia {
		--icon-url: url('cut-media.svg');
	}

	&.DateArrow {
		--icon-url: url('date-arrow.svg');
	}

	&.DatePickerMonthActive {
		--icon-url: url('date-picker-month-active.svg');
	}

	&.DatePickerMonthHover {
		--icon-url: url('date-picker-month-hover.svg');
	}

	&.DatePickerMonth {
		--icon-url: url('date-picker-month.svg');
	}

	&.DatePickerYearActive {
		--icon-url: url('date-picker-year-active.svg');
	}

	&.DatePickerYearHover {
		--icon-url: url('date-picker-year-hover.svg');
	}

	&.DatePickerYear {
		--icon-url: url('date-picker-year.svg');
	}

	&.DeleteBinGray {
		--icon-url: url('delete-bin-gray.svg');
	}

	&.DeleteBinWhite {
		--icon-url: url('delete-bin-white.svg');
	}

	&.DeleteGray {
		--icon-url: url('delete-gray.svg');
	}

	&.DeleteWhite {
		--icon-url: url('delete-white.svg');
	}

	&.Delete {
		--icon-url: url('delete.svg');
	}

	&.DesktopActive {
		--icon-url: url('desktop-active.svg');
	}

	&.Desktop {
		--icon-url: url('desktop.svg');
	}

	&.Devices {
		--icon-url: url('devices.svg');
	}

	&.DiskOptionsArrowDown {
		--icon-url: url('disk-options-arrow-down.svg');
	}

	&.DiskOptionsArrowUp {
		--icon-url: url('disk-options-arrow-up.svg');
	}

	&.Disk {
		--icon-url: url('disk.svg');
	}

	&.DoubleArrowGrey {
		--icon-url: url('double-arrow-grey.svg');
	}

	&.DoubleArrow {
		--icon-url: url('double-arrow.svg');
	}

	&.DoubleCircleArrowDisabled {
		--icon-url: url('double-circle-arrow-disabled.svg');
	}

	&.DoubleCircleArrow {
		--icon-url: url('double-circle-arrow.svg');
	}

	&.DownloadCloseDark {
		--icon-url: url('download-close-dark.svg');
	}

	&.DownloadCloseDisabled {
		--icon-url: url('download-close-disabled.svg');
	}

	&.DownloadClose {
		--icon-url: url('download-close.svg');
	}

	&.DownloadConfirmDelete {
		--icon-url: url('download-confirm-delete.png');
	}

	&.DownloadContinueDark {
		--icon-url: url('download-continue-dark.svg');
	}

	&.DownloadContinueDisabled {
		--icon-url: url('download-continue-disabled.svg');
	}

	&.DownloadContinue {
		--icon-url: url('download-continue.svg');
	}

	&.DownloadErrorIcon {
		--icon-url: url('download-error-icon.svg');
	}

	&.DownloadPauseDark {
		--icon-url: url('download-pause-dark.svg');
	}

	&.DownloadPauseDisabled {
		--icon-url: url('download-pause-disabled.svg');
	}

	&.DownloadPauseIcon {
		--icon-url: url('download-pause-icon.svg');
	}

	&.DownloadPause {
		--icon-url: url('download-pause.svg');
	}

	&.DownloadProgressIcon {
		--icon-url: url('download-progress-icon.svg');
	}

	&.DownloadSuccessIcon {
		--icon-url: url('download-success-icon.svg');
	}

	&.EditorActive {
		--icon-url: url('editor-active.svg');
	}

	&.EditorGray {
		--icon-url: url('editor-gray.svg');
	}

	&.Editor {
		--icon-url: url('editor.svg');
	}

	&.ErrorPage {
		--icon-url: url('error-page.svg');
	}

	&.ErrorToast {
		--icon-url: url('error-toast.svg');
	}

	&.Error {
		--icon-url: url('error.svg');
	}

	&.Exit {
		--icon-url: url('exit.svg');
	}

	&.ExpandCollpase {
		--icon-url: url('expand-collpase.svg');
	}

	&.FavoriteColor {
		--icon-url: url('favorite-color.svg');
	}

	&.FavoriteFill {
		--icon-url: url('favorite-fill.svg');
	}

	&.Favorite {
		--icon-url: url('favorite.svg');
	}

	&.FileDisable {
		--icon-url: url('file-disable.svg');
	}

	&.FileEnable {
		--icon-url: url('file-enable.svg');
	}

	&.FileLoadDisplay {
		--icon-url: url('file-load-display.svg');
	}

	&.FileTrackAudioOff {
		--icon-url: url('file-track-audio-off.svg');
	}

	&.FileTrackAudio {
		--icon-url: url('file-track-audio.svg');
	}

	&.FileTrackGradient {
		--icon-url: url('file-track-gradient.svg');
	}

	&.FilterDateActive {
		--icon-url: url('filter-date-active.png');
	}

	&.FilterDateDark {
		--icon-url: url('filter-date-dark.svg');
	}

	&.FilterDateHover {
		--icon-url: url('filter-date-hover.png');
	}

	&.FilterDate {
		--icon-url: url('filter-date.svg');
	}

	&.FilterNameActive {
		--icon-url: url('filter-name-active.svg');
	}

	&.FilterNameHover {
		--icon-url: url('filter-name-hover.svg');
	}

	&.FilterName {
		--icon-url: url('filter-name.svg');
	}

	&.FilterStatusActive {
		--icon-url: url('filter-status-active.svg');
	}

	&.FilterStatusHover {
		--icon-url: url('filter-status-hover.svg');
	}

	&.FilterStatus {
		--icon-url: url('filter-status.svg');
	}

	&.Forward {
		--icon-url: url('forward.svg');
	}

	&.GasStation {
		--icon-url: url('gas-station.svg');
	}

	&.GraphicsActive {
		--icon-url: url('graphics-active.svg');
	}

	&.Graphics {
		--icon-url: url('graphics.svg');
	}

	&.GrayArrowUp {
		--icon-url: url('gray-arrow-up.svg');
	}

	&.GrayArrow {
		--icon-url: url('gray-arrow.svg');
	}

	&.Hospital {
		--icon-url: url('hospital.svg');
	}

	&.Hotel {
		--icon-url: url('hotel.svg');
	}

	&.ImageFile {
		--icon-url: url('image-file.svg');
	}

	&.Index {
		--icon-url: url('index.ts');
	}

	&.InfoToast {
		--icon-url: url('info-toast.svg');
	}

	&.ItemStatusOff {
		--icon-url: url('item-status-off.svg');
	}

	&.ItemStatusOn {
		--icon-url: url('item-status-on.svg');
	}

	&.LibraryActive {
		--icon-url: url('library-active.svg');
	}

	&.LibraryFolder {
		--icon-url: url('library-folder.svg');
	}

	&.LibraryUpMenu {
		--icon-url: url('library-up-menu.svg');
	}

	&.Library {
		--icon-url: url('library.svg');
	}

	&.LoadFile {
		--icon-url: url('load-file.svg');
	}

	&.Lock {
		--icon-url: url('lock.png');
	}

	&.LogoColor {
		--icon-url: url('logo-color.svg');
	}

	&.Logo {
		--icon-url: url('logo.svg');
	}

	&.Malls {
		--icon-url: url('malls.svg');
	}

	&.MiniDelete {
		--icon-url: url('mini-delete.svg');
	}

	&.MonitorDelete {
		--icon-url: url('monitor-delete.svg');
	}

	&.MonitorEdit {
		--icon-url: url('monitor-edit.svg');
	}

	&.MonitorHorizontalGreen {
		--icon-url: url('monitor-horizontal-green.svg');
	}

	&.MonitorHorizontal {
		--icon-url: url('monitor-horizontal.svg');
	}

	&.MonitorOff {
		--icon-url: url('monitor-off.svg');
	}

	&.MonitorOn {
		--icon-url: url('monitor-on.svg');
	}

	&.MonitorVerticalGreen {
		--icon-url: url('monitor-vertical-green.svg');
	}

	&.MonitorVertical {
		--icon-url: url('monitor-vertical.svg');
	}

	&.MonitorWhite {
		--icon-url: url('monitor-white.svg');
	}

	&.Monitor {
		--icon-url: url('monitor.svg');
	}

	&.MonitorsActive {
		--icon-url: url('monitors-active.svg');
	}

	&.MonitorsFilterCleanActive {
		--icon-url: url('monitors-filter-clean-active.svg');
	}

	&.MonitorsFilterClean {
		--icon-url: url('monitors-filter-clean.svg');
	}

	&.MonitorsGroupSyncGreen {
		--icon-url: url('monitors-group-sync-green.svg');
	}

	&.MonitorsGroupSyncWhite {
		--icon-url: url('monitors-group-sync-white.svg');
	}

	&.MonitorsGroupSync {
		--icon-url: url('monitors-group-sync.svg');
	}

	&.MonitorsGroupWallGreen {
		--icon-url: url('monitors-group-wall-green.svg');
	}

	&.MonitorsGroupWallWhite {
		--icon-url: url('monitors-group-wall-white.svg');
	}

	&.MonitorsGroupWall {
		--icon-url: url('monitors-group-wall.svg');
	}

	&.Monitors {
		--icon-url: url('monitors.svg');
	}

	&.PasteMedia {
		--icon-url: url('paste-media.svg');
	}

	&.PauseButtonHover {
		--icon-url: url('pause-button-hover.svg');
	}

	&.PauseButton {
		--icon-url: url('pause-button.svg');
	}

	&.Pause {
		--icon-url: url('pause.svg');
	}

	&.Pharma {
		--icon-url: url('pharma.svg');
	}

	&.PlayButtonHover {
		--icon-url: url('play-button-hover.svg');
	}

	&.PlayButton {
		--icon-url: url('play-button.svg');
	}

	&.Play {
		--icon-url: url('play.svg');
	}

	&.PlayerButton {
		--icon-url: url('player-button.svg');
	}

	&.PlayerSearchDevices {
		--icon-url: url('player-search-devices.svg');
	}

	&.PlaylistAdded {
		--icon-url: url('playlist-added.svg');
	}

	&.PlaylistCancel {
		--icon-url: url('playlist-cancel.svg');
	}

	&.PlaylistCircleOff {
		--icon-url: url('playlist-circle-off.svg');
	}

	&.PlaylistCircleOn {
		--icon-url: url('playlist-circle-on.svg');
	}

	&.PlaylistConfirm {
		--icon-url: url('playlist-confirm.svg');
	}

	&.PlaylistDelete {
		--icon-url: url('playlist-delete.svg');
	}

	&.PlaylistDetele {
		--icon-url: url('playlist-detele.svg');
	}

	&.PlaylistEdit {
		--icon-url: url('playlist-edit.svg');
	}

	&.PlaylistOff {
		--icon-url: url('playlist-off.svg');
	}

	&.PlaylistOn {
		--icon-url: url('playlist-on.svg');
	}

	&.Playlist {
		--icon-url: url('playlist.svg');
	}

	&.PlaylistsActive {
		--icon-url: url('playlists-active.svg');
	}

	&.PlaylistsGray {
		--icon-url: url('playlists-gray.svg');
	}

	&.Playlists {
		--icon-url: url('playlists.svg');
	}

	&.PlusAdd {
		--icon-url: url('plus-add.svg');
	}

	&.ProfileGray {
		--icon-url: url('profile-gray.svg');
	}

	&.ProfileLogoutGreen {
		--icon-url: url('profile-logout-green.svg');
	}

	&.ProfileLogout {
		--icon-url: url('profile-logout.svg');
	}

	&.ProfileSettings {
		--icon-url: url('profile-settings.svg');
	}

	&.ProfileStatistics {
		--icon-url: url('profile-statistics.svg');
	}

	&.Profile {
		--icon-url: url('profile.svg');
	}

	&.ProjectErrorIcon {
		--icon-url: url('project-error-icon.svg');
	}

	&.Rename {
		--icon-url: url('rename.svg');
	}

	&.Rewind {
		--icon-url: url('rewind.svg');
	}

	&.Sauna {
		--icon-url: url('sauna.svg');
	}

	&.SaveAsHover {
		--icon-url: url('save-as-hover.svg');
	}

	&.SaveAs {
		--icon-url: url('save-as.svg');
	}

	&.SavePlaylist {
		--icon-url: url('save-playlist.svg');
	}

	&.SaveWhite {
		--icon-url: url('save-white.svg');
	}

	&.Save {
		--icon-url: url('save.svg');
	}

	&.SearchMonitor {
		--icon-url: url('search-monitor.svg');
	}

	&.Search {
		--icon-url: url('search.svg');
	}

	&.SelectArrowChosen {
		--icon-url: url('select-arrow-chosen.svg');
	}

	&.SelectArrow {
		--icon-url: url('select-arrow.svg');
	}

	&.SelectWhite {
		--icon-url: url('select-white.svg');
	}

	&.SendEmail {
		--icon-url: url('send-email.svg');
	}

	&.Send {
		--icon-url: url('send.svg');
	}

	&.SettingsActive {
		--icon-url: url('settings-active.svg');
	}

	&.Settings {
		--icon-url: url('settings.svg');
	}

	&.SortAscending {
		--icon-url: url('sort-ascending.svg');
	}

	&.SortDescending {
		--icon-url: url('sort-descending.svg');
	}

	&.SortHover {
		--icon-url: url('sort-hover.svg');
	}

	&.Sort {
		--icon-url: url('sort.svg');
	}

	&.StatisticsNext {
		--icon-url: url('statistics-next.svg');
	}

	&.StatisticsPrevious {
		--icon-url: url('statistics-previous.svg');
	}

	&.Statistics {
		--icon-url: url('statistics.svg');
	}

	&.StatusSort {
		--icon-url: url('status-sort.svg');
	}

	&.SubMenuActive {
		--icon-url: url('sub-menu-active.svg');
	}

	&.SuccessToast {
		--icon-url: url('success-toast.svg');
	}

	&.TextResetActive {
		--icon-url: url('text-reset-active.svg');
	}

	&.TextResetHover {
		--icon-url: url('text-reset-hover.svg');
	}

	&.TextReset {
		--icon-url: url('text-reset.svg');
	}

	&.TimelineAudio {
		--icon-url: url('timeline-audio.svg');
	}

	&.TimelineControlsBackward {
		--icon-url: url('timeline-controls-backward.svg');
	}

	&.TimelineControlsCursor {
		--icon-url: url('timeline-controls-cursor.svg');
	}

	&.TimelineControlsCut {
		--icon-url: url('timeline-controls-cut.svg');
	}

	&.TimelineControlsDelete {
		--icon-url: url('timeline-controls-delete.svg');
	}

	&.TimelineControlsForward {
		--icon-url: url('timeline-controls-forward.svg');
	}

	&.TimelineControlsOne {
		--icon-url: url('timeline-controls-one.svg');
	}

	&.TimelineControlsSelect {
		--icon-url: url('timeline-controls-select.svg');
	}

	&.TimelineControlsThree {
		--icon-url: url('timeline-controls-three.svg');
	}

	&.TimelineControlsZoom {
		--icon-url: url('timeline-controls-zoom.svg');
	}

	&.TimelinePointer {
		--icon-url: url('timeline-pointer.svg');
	}

	&.TimelineVideo {
		--icon-url: url('timeline-video.svg');
	}

	&.ToastCloseButton {
		--icon-url: url('toast-close-button.svg');
	}

	&.TopArrowLight {
		--icon-url: url('top-arrow-light.svg');
	}

	&.TopArrow {
		--icon-url: url('top-arrow.svg');
	}

	&.TrackAudio {
		--icon-url: url('track-audio.svg');
	}

	&.TrackMoveLeft {
		--icon-url: url('track-move-left.svg');
	}

	&.TrackMoveRight {
		--icon-url: url('track-move-right.svg');
	}

	&.TrashSelect {
		--icon-url: url('trash-select.svg');
	}

	&.TrashWhite {
		--icon-url: url('trash-white.svg');
	}

	&.Upload {
		--icon-url: url('upload.svg');
	}

	&.User {
		--icon-url: url('user.svg');
	}

	&.UsersActive {
		--icon-url: url('users-active.svg');
	}

	&.Users {
		--icon-url: url('users.svg');
	}

	&.VideoFile {
		--icon-url: url('video-file.svg');
	}

	&.VideoPic {
		--icon-url: url('video-pic.svg');
	}

	&.VideoProjectIcon {
		--icon-url: url('video-project-icon.svg');
	}

	&.VideoStub {
		--icon-url: url('video-stub.svg');
	}

	&.WarningToast {
		--icon-url: url('warning-toast.svg');
	}

	&.Warning {
		--icon-url: url('warning.svg');
	}

	&.WhiteArrowUp {
		--icon-url: url('white-arrow-up.svg');
	}

	&.WhiteArrow {
		--icon-url: url('white-arrow.svg');
	}

	&.WhiteTrash {
		--icon-url: url('white-trash.svg');
	}

	&.XClear {
		--icon-url: url('x-clear.svg');
	}
}
