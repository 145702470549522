@import '../../styles/variables.scss';

.filters {
	&__search-button {
		background-color: use-color('dark-green');
		background-image: url('../../assets/icons/filter-name.svg');
		background-position: 13px;
		background-repeat: no-repeat;
		border: 3px solid use-color('dark-green');
		border-radius: 2px;
		color: use-color('white');
		font-size: 18px;
		height: 40px;
		line-height: 21px;
		padding-left: 32px;
		text-align: left;
		transition: all 0.1s;
		width: 105px;

		&:hover {
			border: 3px solid use-color('light-dim');
		}

		&:active {
			border: 3px solid rgba(0, 0, 0, 0.2);
		}
	}

	&__reset-button {
		border: 1px solid use-color('gray-light');
		border-radius: 2px;
		color: use-color('dark');
		font-size: 18px;
		height: 40px;
		line-height: 21px;
		margin-left: 5px;
		width: 100px;
	}

	&__text-layout {
		align-items: center;
		background-color: use-color('white');
		display: flex;
		filter: drop-shadow(0 4px 8px rgba(71, 79, 100, 0.6));
		height: 70px;
		justify-content: center;
		left: 50%;
		padding: 0 10px 0 15px;
		position: absolute;
		top: 38px;
		transform: translateX(-50%);
		width: 400px;
		z-index: 100;
	}

	&__text-input-layout {
		margin-right: 10px;
		position: relative;
		width: 250px;
	}

	&__text-input {
		border: 1px solid use-color('gray-light');
		border-radius: 2px;
		font-size: 18px;
		height: 40px;
		line-height: 21px;
		padding-left: 10px;
		transition: all 0.1s;
		width: 250px;

		&:hover {
			border: 1px solid use-color('white');
			box-shadow: 0 0 0 3px use-color('light-dim');
		}

		&:focus {
			border: 1px solid use-color('gray-light');
			box-shadow: 0 0 0 3px use-color('light-dim');
		}
	}

	&__text-reset {
		background-image: url('../../assets/icons/text-reset.svg');
		height: 12px;
		position: absolute;
		right: 14px;
		top: 14px;
		transition: all 0.1s;
		width: 12px;

		&:hover {
			background-image: url('../../assets/icons/text-reset-hover.svg');
		}

		&:active {
			background-image: url('../../assets/icons/text-reset-active.svg');
		}
	}

	&__date-layout {
		background-color: use-color('white');
		box-shadow: 0 4px 8px rgba(71, 79, 100, 0.5);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		left: 50%;
		position: absolute;
		top: 38px;
		transform: translateX(-50%);
		z-index: 100;

		.calendar {
			box-shadow: none;
		}
	}

	&__date-labels {
		left: 45px;
		position: absolute;
		top: 11px;
	}

	&__date-label {
		color: use-color('dark');
		font-size: 14px;
		line-height: 16px;
		margin-bottom: 15px;
		text-align: end;
	}

	&__date-next-month {
		background-image: url('../../assets/icons/date-picker-month.svg');
		height: 8px;
		margin-right: 30px;
		width: 5px;

		&:hover {
			background-image: url('../../assets/icons/date-picker-month-hover.svg');
		}

		&:active {
			background-image: url('../../assets/icons/date-picker-month-active.svg');
		}
	}

	&__date-prev-month {
		background-image: url('../../assets/icons/date-picker-month.svg');
		height: 8px;
		margin-left: 30px;
		margin-right: 0;
		transform: rotate(180deg);
		width: 5px;

		&:hover {
			background-image: url('../../assets/icons/date-picker-month-hover.svg');
		}

		&:active {
			background-image: url('../../assets/icons/date-picker-month-active.svg');
		}
	}

	&__date-next-year {
		background-image: url('../../assets/icons/date-picker-year.svg');
		height: 8px;
		width: 12px;

		&:hover {
			background-image: url('../../assets/icons/date-picker-year-hover.svg');
		}

		&:active {
			background-image: url('../../assets/icons/date-picker-year-active.svg');
		}
	}

	&__date-prev-year {
		background-image: url('../../assets/icons/date-picker-year.svg');
		height: 8px;
		transform: rotate(180deg);
		width: 12px;

		&:hover {
			background-image: url('../../assets/icons/date-picker-year-hover.svg');
		}

		&:active {
			background-image: url('../../assets/icons/date-picker-year-active.svg');
		}
	}

	&__date-actions {
		margin: 7px auto;
	}

	&__radio-layout {
		background-color: use-color('white');
		filter: drop-shadow(0 4px 8px rgba(71, 79, 100, 0.6));
		left: 50%;
		padding: 25px 15px 15px;
		position: absolute;
		top: 38px;
		transform: translateX(-50%);
		white-space: normal;
		width: 160px;
		z-index: 100;
	}

	&__radio-input-layout {
		align-items: center;
		display: flex;
		margin-bottom: 20px;
	}

	&__radio-label {
		color: use-color('dark');
		font-size: 16px;
		line-height: 18px;
		margin-left: 10px;
	}

	&__radio-search {
		margin: 0 13px;
	}

	.react-date-picker,
	.react-daterange-picker {
		&__calendar {
			&--closed {
				display: none;
			}
		}
	}
}
