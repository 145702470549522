@import 'styles/variables';

.wallet {
	&__balance {
		align-items: center;
		column-gap: 15px;
		display: flex;
		padding: 15px 0;
		position: relative;

		&-label {
			color: use-color('gray');
			font-weight: 500;
		}

		h1 {
			font-size: 32px;
			font-weight: 500;
		}
	}

	&__nav {
		margin-top: 20px;
	}

	&-history {
		&__date,
		&__sum {
			font-weight: 500;

			&.debit {
				color: use-color('green');
			}
		}
	}
}
