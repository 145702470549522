.p-4-em {
	padding: 4em;
}

.ml-auto {
	margin-left: auto;
}

// Fonts
.font {
	&-xx-large {
		font-size: xx-large;
	}

	&-x-large {
		font-size: x-large;
	}

	&-large {
		font-size: large;
	}

	&-italic {
		font-style: italic;
	}

	&-bold {
		font-weight: 900;
	}

	&-100 {
		font-weight: 100;
	}

	&-200 {
		font-weight: 200;
	}

	&-300 {
		font-weight: 300;
	}

	&-400 {
		font-weight: 400;
	}

	&-500 {
		font-weight: 500;
	}

	&-600 {
		font-weight: 600;
	}
}
