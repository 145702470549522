@import '../../styles/chartist-settings.scss';
@import '../../styles/variables.scss';

.current-user {
	&__section {
		background-color: use-color('white');
		border-radius: 10px;

		&--left {
			display: flex;
			flex-direction: column;
		}

		&--playlists {
			margin-top: 84px;
		}
	}

	&__section-top {
		align-items: center;
		display: flex;
		height: 62px;
		justify-content: space-between;
		padding: 0 18px;
		position: relative;
	}

	&__title {
		background-position: left center;
		background-repeat: no-repeat;
		font-size: 22px;
		line-height: 26px;
		padding-left: 36px;
	}

	&__title-info {
		align-items: center;
		display: flex;
		font-size: 12px;
		justify-content: flex-end;
		line-height: 14px;
		text-align: right;
		text-transform: lowercase;
		width: 200px;
	}

	&__title-info-value {
		align-items: center;
		background: use-color('dark');
		border-radius: 5px;
		color: use-color('white');
		display: flex;
		font-size: 22px;
		height: 30px;
		justify-content: center;
		line-height: 26px;
		margin-left: 10px;
		padding: 0 10px;
	}

	&__devices,
	&__playlists {
		margin-top: 18px;
	}

	&__devices-option,
	&__playlists-option {
		align-items: center;
		display: flex;
		justify-content: space-between;
		margin-bottom: 36px;
	}

	&__devices-label,
	&__playlists-label {
		color: use-color('gray-light');
		flex-shrink: 0;
		font-size: 16px;
		line-height: 18px;
	}

	&__devices-line,
	&__playlists-line {
		border-bottom: 1px dashed use-color('dark');
		margin: 0 24px;
		width: 100%;
	}

	&__devices-value,
	&__playlists-value {
		align-items: center;
		border-radius: 5px;
		color: use-color('white');
		display: flex;
		flex-shrink: 0;
		font-size: 32px;
		height: 36px;
		justify-content: center;
		width: 72px;
	}

	&__devices-value {
		&--online {
			background-color: use-color('green');
		}

		&--offline {
			background-color: use-color('gray-light');
		}

		&--drafts {
			background-color: use-color('light');
			color: use-color('gray-light');
		}
	}

	&__playlists-value {
		&--on {
			background-color: use-color('green');
		}

		&--off {
			background-color: use-color('gray-light');
		}
	}

	&__add-create-button {
		height: 32px;
		width: 144px;
	}

	&__info {
		padding: 0 18px;
	}

	&__info-field {
		font-size: 12px;
		margin: 18px 0 0 8px;
		text-transform: lowercase;
	}

	&__info-value {
		background: use-color('light');
		border-radius: 5px;
		color: use-color('gray');
		font-size: 16px;
		height: 36px;
		margin-top: 5px;
		padding: 10px;
	}

	&__disk {
		align-items: center;
		display: flex;
		justify-content: space-between;
		padding: 30px 40px;
	}

	&__disk-options {
		position: absolute;
		right: 18px;
		top: 13px;
		z-index: 10;
	}

	&__disk-options-button {
		background-color: use-color('light');
		background-image: url('../../assets/icons/disk-options-arrow-down.svg');
		background-position-x: 8px;
		background-position-y: center;
		background-repeat: no-repeat;
		background-size: 22px;
		border: 1px solid use-color('light');
		border-radius: 5px;
		color: use-color('gray');
		font-size: 16px;
		height: 36px;
		line-height: 18px;
		padding: 8px 10px;
		text-align: right;
		width: 216px;

		&--opened {
			background-color: use-color('white');
			background-image: url('../../assets/icons/disk-options-arrow-up.svg');
			border: 1px solid use-color('gray-light');
			border-bottom: 0;
			border-radius: 5px 5px 0 0;
		}
	}

	&__disk-options-list {
		align-items: flex-end;
		background-color: use-color('white');
		border: 1px solid use-color('gray-light');
		border-radius: 0 0 5px 5px;
		border-top: 0;
		color: use-color('gray');
		display: flex;
		flex-direction: column;
		font-size: 16px;
		height: 180px;
		justify-content: space-around;
		line-height: 18px;
		padding: 0 10px;
		width: 216px;
	}

	&__disk-option {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	&__disk-option-checkbox {
		height: 22px;
		width: 22px;
	}

	&__pie {
		position: relative;

		&--playing {
			stroke: use-color('red');
		}

		&--not-playing {
			stroke: use-color('gray');
		}

		&--used {
			stroke: use-color('gray-light');
		}

		&--free {
			stroke: #dbe0f2;
		}
	}

	&__free-space {
		color: use-color('gray');
		font-size: 16px;
		left: 50%;
		position: absolute;
		text-align: center;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	&__free-space-value {
		font-weight: 400;
		margin-top: 18px;
		text-align: center;
	}

	&__space-field {
		align-items: center;
		display: flex;
		font-size: 12px;
		margin-bottom: 28px;

		&--total {
			font-size: 16px;
		}
	}

	&__space-value {
		display: inline-block;
		font-weight: 400;
		padding-left: 5px;

		&--total {
			font-size: 16px;
		}
	}

	&__space-label {
		display: inline-block;
		height: 10px;
		margin-right: 18px;
		width: 10px;

		&--total {
			background-color: #dbe0f2;
		}

		&--busy {
			background-color: use-color('gray-light');
		}

		&--is-used {
			background-color: use-color('red');
		}

		&--is-not-used {
			background-color: use-color('gray');
		}
	}
}

.profile {
	&#userProfile,
	&#metrics {
		align-items: flex-start;
		display: grid;
		gap: 10px;
		grid-template-rows: auto 1fr;

		.page-plate {
			&.details {
				grid-row: span 2;
			}
		}
	}

	&#userProfile {
		grid-template-columns: repeat(2, 1fr);
		width: 960px;

		.page-plate {
			&__input {
				width: 220px;
			}
		}
	}

	&#metrics {
		grid-template-columns: repeat(2, minmax(auto, 576px));
	}
}
